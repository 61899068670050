import React from "react";
import "./App.css";

function App() {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "https://storage.googleapis.com/kamilbucket1992/hello.txt"; 
    link.download = "hello.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Hello, React!</h1>
        <img src="https://storage.googleapis.com/kamilbucket1992/camaro.png" alt="Logo" />
        <button onClick={handleDownload}>Download the text file</button>
      </header>
    </div>
  );
}

export default App;

